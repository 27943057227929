import React from 'react';
import { get } from 'lodash';
import { Link } from 'gatsby';
import AccountWrapper from '../AccountWrapper';
import ReferralCouponDialog from '../ReferralCouponDialog';
import Layout, { AuthContext } from '../Layout';
import PlaceholderImage from '../PlaceholderImage';
import LoadingContainer from '../LoadingContainer';
import farmPlaceholder from '../../images/farm_placeholder.svg';
import { makeCancellable, getLocalStorage, AUTH_USER, FAVORITES_TIMESTAMP, setLocalStorage, FAVORITE_FARMS, FAVORITE_PRODUCTS, unexpectedErrorFunction, fetchWithToken } from '../../utils';
import { FOOD_ROUTE, FARM_ROUTE } from '../../constants';
import { BASE_PATH } from '../../config';

const FavoriteItem = ({name, info, isActive, imageURL, linkURL}) => {
    const content = (
        <>
            <PlaceholderImage className='img-container' src={imageURL} />
            <div className='content'>
                <h5 className='overflow-ellipsis'>{name}</h5>
                <p className='text-muted overflow-ellipsis'>{info}</p>
                {!isActive && <p className='text-muted unavailable-msg'>*CURRENTLY UNAVAILABLE*</p>}
            </div>
        </>
    )
    return (
        <div className='grid-item-favorite'>
            {isActive ? <Link className='big-link-container' to={linkURL}>{content}</Link> : <div className='big-link-container'>{content}</div>}
        </div>
    )
}

const FavoritesPage = ({ location }) => {
    const couponCode = get(location, 'state.couponCode');
    const authUser = getLocalStorage(AUTH_USER) || {};
    const [fetching, setFetching] = React.useState(true);
    const [favorites, setFavorites] = React.useState(null);
    const user = React.useContext(AuthContext);
    React.useEffect(() => {
        if (user && !favorites) {
            const wrappedFunction = makeCancellable(fetchWithToken(user.getIdToken(), `${BASE_PATH}/getUserFavorites`, '&getAdditionalProperties=true'));
            wrappedFunction.promise
            .then(({ data }) => {
                setFavorites(data);
                const favoriteFarms = data.favoriteFarms.map(({ id }) => id);
                const favoriteProducts = data.favoriteProducts.map(({ id }) => id);
                setLocalStorage(FAVORITES_TIMESTAMP, Date.now())
                setLocalStorage(FAVORITE_FARMS, favoriteFarms);
                setLocalStorage(FAVORITE_PRODUCTS, favoriteProducts);
                setFetching(false);
            })
            .catch(unexpectedErrorFunction)
            return () => wrappedFunction.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    function renderContent() {
        if (fetching) {
            return <LoadingContainer text="Loading favorites..." />
        }
        else if (favorites && favorites.favoriteFarms.length === 0 && favorites.favoriteProducts.length === 0) {
            return (
                <div className='account-placeholder'>
                    <div className='image-container'><img src={farmPlaceholder} alt="Animated farm" /></div>
                    <p>Your favorite farms and products will show up here; you just have to add them first!</p>
                </div>
            )
        }
        else if (favorites) {
            return (
                <div>
                    {favorites.favoriteProducts.length > 0 && 
                    <div className='margin-bottom-4'>
                        <h5 className='underline-heading'>Your favorite foods</h5>
                        <div className='grid-container add-width-variable'>
                            {favorites.favoriteProducts.map(({id, name, imageURL, farmName, path}) => {
                                return (
                                    <FavoriteItem 
                                        name={name} 
                                        imageURL={imageURL} 
                                        info={farmName} 
                                        isActive={true} // Even if the product is inactive, you should still be able to visit the page
                                        linkURL={`${FOOD_ROUTE}${path}`}
                                        key={id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    }
                    {favorites.favoriteFarms.length > 0 && 
                    <div className='margin-bottom-4'>
                        <h5 className='underline-heading'>Your favorite farms</h5>
                        <div className='grid-container add-width-variable'>
                            {favorites.favoriteFarms.map(({id, name, profileImageURL, location, path, status}) => {
                                return (
                                    <FavoriteItem 
                                        name={name} 
                                        imageURL={profileImageURL} 
                                        info={location} 
                                        isActive={status === "ACTIVE"}
                                        linkURL={`${FARM_ROUTE}${path}`}
                                        key={id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    }
                </div>
            )
        }
        return null;
    }
    return (
        <AccountWrapper authUser={authUser}>
            {renderContent()}
            <ReferralCouponDialog couponCode={couponCode} />
        </AccountWrapper>
    )
}

export default ({ location }) => {
    return (
        <Layout pageTitle="Your favorites">
            <FavoritesPage location={location} />
        </Layout>
    )
}