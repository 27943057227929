import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from './Dialog';
import Icon from './Icon';

export default ({ couponCode }) => {
    const [open, setOpen] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    function handleClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (couponCode && !open) {
            setOpen(true);
        }
        // eslint-disable-next-line
    }, [couponCode])
    async function handleCopy() {
        if (typeof window !== "undefined") {
            await window.navigator.clipboard.writeText(couponCode);
            setCopied(true);
        }
    }
    return (
        <Dialog open={open} maxWidth={450} disableBackdropClick>
            <div className='add-padding-2'>
                <h5 className='margin-bottom-2'>Account created</h5>
                <p className='margin-bottom-2'>Welcome to till! As promised, here is your coupon code. Please use this coupon code at the checkout page when placing your order to get <strong>$15 off</strong>. You will also receive an email confirmation with this coupon code.</p>
                <input 
                    className="form-input"
                    readOnly
                    value={couponCode}
                />
                <div>
                    <Grid container alignItems='center' justify='space-between'>
                        <Grid item>
                            <button className={`btn primary-btn grid-btn small-btn${copied ? ' disabled' : ''}`} type="button" onClick={handleCopy} disabled={copied}>
                                <span><Icon iconName="copy" width={16} /></span>
                                <span>{copied ? 'CODE COPIED' : 'COPY CODE'}</span>
                            </button>
                        </Grid>
                        <Grid item>
                            <button className={`btn small-btn full-width white-btn`} onClick={handleClose}>CLOSE</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Dialog>
    )
}