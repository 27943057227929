import React from 'react';
import AccountWrapper from '../AccountWrapper';
import DeliveryLocationList from '../DeliveryLocationList';
import PaymentMethodList from '../PaymentMethodList';
import Layout, { AuthContext } from '../Layout';
import LoadingContainer from '../LoadingContainer';
import { getLocalStorage, AUTH_USER, formatPhoneNumber, unexpectedErrorFunction, postWithToken, fetchWithToken, makeCancellable } from '../../utils';
import AccountDetailsDialog from '../AccountDetailsDialog';
import { BASE_PATH } from '../../config';

const SettingsPage = () => {
    const [authUser, setAuthUser] = React.useState(getLocalStorage(AUTH_USER))
    const [fetching, setFetching] = React.useState("init");
    const [accountDetails, setAccountDetails] = React.useState({});
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const user = React.useContext(AuthContext);

    function updateUserDefault(updateValue, updateType) {
        setFetching("update");
        postWithToken(user.getIdToken(), `${BASE_PATH}/setUserDefault`, { updateType, updateValue })
        .then(() => {
            setFetching("");
            setAccountDetails({ ...accountDetails, [updateType]: updateValue });
        })
        .catch(unexpectedErrorFunction);
    }
    function handleLocationAdded(location) {
        setFetching("update");
        postWithToken(user.getIdToken(), `${BASE_PATH}/updateDeliveryLocations`, { 
            deliveryLocations: [...accountDetails.deliveryLocations, location],
            defaultDeliveryLocation: location
        })
        .then((res) => {
            setAccountDetails({ 
                ...accountDetails,
                deliveryLocations: [...accountDetails.deliveryLocations, location],
                defaultDeliveryLocation: location
            });
        })
        .catch(unexpectedErrorFunction)
        .finally(() => setFetching(""));
    }
    function handlePaymentMethodCreated(paymentMethod) {
        setAccountDetails({
            ...accountDetails, 
            paymentMethods: [...accountDetails.paymentMethods, paymentMethod],
            defaultPaymentMethod: paymentMethod
        })
    }
    function processPaymentRemoval(data) {
        setAccountDetails({...accountDetails, ...data});
    }
    React.useEffect(() => {
        if (user) {
            const wrappedFunction = makeCancellable(fetchWithToken(user.getIdToken(true), `${BASE_PATH}/getAccountDetails`))
            wrappedFunction.promise.then(({ data }) => {
                if (data.defaultPaymentMethod) {
                    data.paymentMethods.sort((a, b) => {
                        if (a.id === data.defaultPaymentMethod.id) {
                            return -1;
                        }
                        return 1;
                    })
                }
                if (!data.deliveryLocations) {
                    data.deliveryLocations = [];
                }
                else if (data.defaultDeliveryLocation) {
                    data.deliveryLocations.sort((a, b) => {
                        if (a === data.defaultDeliveryLocation) {
                            return -1;
                        }
                        return 1;
                    })
                }
                setAccountDetails(data);
                setFetching("");
            })
            .catch(unexpectedErrorFunction)
            return () => wrappedFunction.cancel();
        }
    }, [user]);
    return (
        <AccountWrapper authUser={authUser}>
            <div className='grid-container add-width-1'>
                <div className='grid-item-3 add-padding'>
                    <div className='paper add-padding'>
                        <h5 className='underline-heading'>Personal information</h5>
                        <p><strong>Name: </strong>{authUser.displayName}</p>
                        <p><strong>Email: </strong>{authUser.email}</p>
                        <p><strong>Phone: </strong>{authUser.phoneNumber ? formatPhoneNumber(authUser.phoneNumber) : 'None provided'}</p>
                        <div className='add-margin-2'>
                            <AccountDetailsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} authUser={authUser} setAuthUser={setAuthUser} />
                        </div>
                    </div>
                </div>
                <div className='grid-item-3 add-padding'>
                    <div className='paper add-padding'>
                        <h5 className='underline-heading'>Payment methods</h5>
                        {fetching === "init" ? 
                        <LoadingContainer altClass=' small' /> : 
                        <PaymentMethodList 
                            paymentMethods={accountDetails.paymentMethods}
                            selectedPaymentMethod={accountDetails.defaultPaymentMethod}
                            stripeCustomerID={accountDetails.stripeCustomerID}
                            processPaymentRemoval={processPaymentRemoval}    
                            handlePaymentMethodCreated={handlePaymentMethodCreated}   
                            handleSelectClick={(value) => updateUserDefault(value, "defaultPaymentMethod")}             
                        />
                        }
                    </div>
                </div>
                <div className='grid-item-3 add-padding'>
                    <div className='paper add-padding overflow-visible'>
                        <h5 className='underline-heading'>Delivery locations</h5>
                        {fetching === "init" ? 
                        <LoadingContainer altClass=' small' /> : 
                        <DeliveryLocationList 
                            deliveryLocations={accountDetails.deliveryLocations}
                            selectedDeliveryLocation={accountDetails.defaultDeliveryLocation}
                            setDeliveryLocations={deliveryLocations => setAccountDetails({...accountDetails, deliveryLocations })}
                            setSelectedDeliveryLocation={(value) => updateUserDefault(value, "defaultDeliveryLocation")}
                            handleLocationsUpdated={data => setAccountDetails({...accountDetails, ...data})}
                            handleLocationAdded={handleLocationAdded}
                        />
                        }
                    </div>
                </div>
            </div>
            {fetching==="update" && <div className='updating-container'><LoadingContainer text="Please wait while we update your preferences..." /></div>}
        </AccountWrapper>
    )
}

export default () => {
    return (
        <Layout pageTitle="Account settings">
            <SettingsPage />
        </Layout>
    )
}