import React from "react";

export default ({ src, className = "" }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  return (
    <div className='placeholder-image-container'>
      <div aria-hidden="true" className='height-container' />
      {!imageLoaded && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          style={{ display: "none" }}
          src={src}
          onLoad={() => setImageLoaded(true)}
          alt="placeholder"
        />
      )}
      {imageLoaded && (
        <img
          className="placeholder-image"
          src={src}
          alt="Item"
        />
      )}
    </div>
  );
};
