import React from 'react';
import { Formik } from 'formik';
import Icon from './Icon';
import Dialog from './Dialog';
import useFirebase from '../useFirebase';


export default ({ dialogOpen, setDialogOpen, authUser }) => {
    const [emailSent, setEmailSent] = React.useState(false);
    const firebase = useFirebase();
    function handleClose() {
        setDialogOpen(false);
    }
    function handleExited() {
        setEmailSent(false);
    }
    return (
        <>
            <Dialog open={dialogOpen} onClose={handleClose} maxWidth={500} disableBackdropClick onExited={handleExited}>
                <div className='add-padding-2'>
                    <button className='btn close-btn' onClick={handleClose}>
                        <Icon width={18} iconName="times" />
                    </button>
                    {emailSent ? 
                    <>
                        <h5 className='margin-bottom-2 text-primary'>Email sent</h5>
                        <p className='margin-bottom-2'>Done! If an account is associated with the email address you provided, you'll receive an email with instructions to reset your password.</p>
                        <div>
                            <button onClick={handleClose} className='btn white-btn full-width'>CLOSE</button>
                        </div>
                    </>
                    :
                    <>
                        <h5 className='margin-bottom-2'>Reset password</h5>
                        <p>Please enter the email address associated with your account and we'll send you an email with a link to update your password.</p>
                        <Formik
                            initialValues={{
                                email: authUser ? authUser.email : ''
                            }}
                            validate={({ email }) => {
                                if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) === false) {
                                    return {email: "Please enter a valid email"}
                                }
                                return {};
                            }}
                            onSubmit={(values, actions) => {
                                firebase.auth().sendPasswordResetEmail(values.email)
                                .then(() => {
                                    actions.setSubmitting(false);
                                    setEmailSent(true);
                                })
                                .catch(err => {
                                    actions.setStatus(err.message || 'An error occurred.');
                                    actions.setSubmitting(false);
                                })
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, status  }) => {
                                const disabled = !values.email || isSubmitting || Boolean(errors.email);
                                function renderStatus() {
                                    if (status) {
                                        return <div className='status-container text-center error'><span>{status}</span></div>
                                    }
                                    return undefined;
                                }
                                return (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            {renderStatus()}
                                            {authUser ? 
                                            <div className='form-input'>
                                                {authUser.email}
                                            </div>
                                            :
                                            <input
                                                name='email'
                                                className={`form-input${touched.email && errors.email ? ' invalid' : ''}`}
                                                type='email'
                                                placeholder='Email'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            }
                                            {touched.email && errors.email && <div className='error-msg'>{errors.email}</div>}
                                            <button type='submit' className={`btn full-width primary-btn${disabled ? ' disabled' : ''}`} disabled={disabled}>
                                                {isSubmitting ? 'SUBMITTING...' : 'SEND EMAIL'}
                                            </button>
                                        </form>
                                    </>
                                )
                            }}
                        </Formik>
                    </>}
                </div>
            </Dialog>
        </>
    )
}