import React from "react";
import moment from "moment";
import Icon from "./Icon";
import Dialog from "./Dialog";
import { toCurrency } from "../utils";
import { ORDER_TOTAL_ITEMS } from "../constants";

export default ({ order, setOrder }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(Boolean(order));
  }, [order]);
  function handleClose() {
    setOpen(false);
  }
  function handleExited() {
    setOrder(null);
  }
  function renderOrderItems() {
    if (order.items.length > 0) {
      return order.items.map(({ itemDisplayName, price, qty }, index) => {
        return (
          <tr key={`item-${index}`}>
            <td>{itemDisplayName}</td>
            <td>{qty}</td>
            <td>${toCurrency(price)}</td>
            <td>${toCurrency(price * qty)}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td
          colSpan="4"
          style={{ padding: "24px 8px", textAlign: "center", color: "#999999" }}
        >
          No items were purchased in this order
        </td>
      </tr>
    );
  }
  function renderOrderTotalItems() {
    let items = [];
    for (const { key, label } of ORDER_TOTAL_ITEMS) {
      if (order[key]) {
        items.push(
          <tr
            key={key}
            className={`total-section-row${
              items.length === 0 ? " total-section-header" : ""
            }`}
          >
            <td colSpan="3">{label}:</td>
            <td>${toCurrency(order[key])}</td>
          </tr>
        );
      }
    }
    return items;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onExited={handleExited}
      maxWidth={650}
      disableBackdropClick
    >
      {order && (
        <div className="add-padding-2">
          <button className="btn close-btn" onClick={handleClose}>
            <Icon width={18} iconName="times" />
          </button>
          <h5>Order {order.id}</h5>
          <table className="order-header-table">
            <tbody>
              <tr>
                <td>Order placed</td>
                <td>{moment(parseInt(order.createdAt)).format("LLL")}</td>
              </tr>
              <tr>
                <td>Delivery date</td>
                <td>{order.deliveryDate}</td>
              </tr>
              <tr>
                <td>Delivery address</td>
                <td>{order.deliveryLocation}</td>
              </tr>
              <tr>
                <td>Order status</td>
                <td>{order.status}</td>
              </tr>
              <tr>
                <td>Payment method</td>
                <td>{order.paymentMethodDetails}</td>
              </tr>
              {order.deliveryNotes && (
                <tr>
                  <td>Delivery notes</td>
                  <td>{order.deliveryNotes}</td>
                </tr>
              )}
              <tr>
                <td>Order details</td>
              </tr>
            </tbody>
          </table>
          {!order.cancelledAt && order.refunds && (
            <div className="refund-list-container">
              <h6 className="text-primary">Refunded items</h6>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.refunds.map(({ amount, reason }, index) => {
                      return (
                        <tr key={`refund-${index}`} valign="top">
                          <td>
                            <strong>${toCurrency(amount)}</strong>
                          </td>
                          <td>{reason || "No description available"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {order.status === "Cancelled" ? (
            <p className="cancelled-msg">
              This order was cancelled {moment(order.cancelledAt).format("LLL")}
            </p>
          ) : (
            <table className="order-items-table margin-top-2">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {renderOrderItems()}
                {order.couponData && (
                  <tr>
                    <td colSpan="3" className="text-primary">
                      Coupon discount (code:{" "}
                      <strong>{order.couponData.code}</strong>)
                    </td>
                    <td className="text-primary">
                      (${toCurrency(order.couponData.discount)})
                    </td>
                  </tr>
                )}
                {renderOrderTotalItems()}
                <tr className="total-section-row order-total-row">
                  <td colSpan="3">Order total:</td>
                  <td>${toCurrency(order.orderTotal)}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="text-right">
            <button className="btn small-btn white-btn" onClick={handleClose}>
              CLOSE
            </button>
          </div>
        </div>
      )}
    </Dialog>
  );
};
