import React from 'react';
import moment from 'moment';
import AccountWrapper from '../AccountWrapper';
import Layout, { AuthContext } from '../Layout';
import Icon from '../Icon';
import LoadingContainer from '../LoadingContainer';
import OrderDialog from '../OrderDialog';
import basketPlaceholder from '../../images/basket_placeholder.svg';
import { getLocalStorage, AUTH_USER, toCurrency, unexpectedErrorFunction, fetchWithToken, makeCancellable } from '../../utils';
import { FOOD_ROUTE } from '../../constants';
import { BASE_PATH } from '../../config';
import { Link } from 'gatsby';

const PAGINATION_LIMIT = 10;
const OrdersPage = () => {
    const authUser = getLocalStorage(AUTH_USER) || {};
    const [orderHistory, setOrderHistory] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);
    const [activeOrder, setActiveOrder] = React.useState(null);
    const [orderPaginationIndex, setOrderPaginationIndex] = React.useState(PAGINATION_LIMIT); 
    const user = React.useContext(AuthContext);

    function refresh() {
        if (user) {
            setOrderPaginationIndex(PAGINATION_LIMIT);
            setFetching(true);
            const wrappedFunction = makeCancellable(fetchWithToken(user.getIdToken(), `${BASE_PATH}/getOrderHistory`));
            wrappedFunction.promise
            .then(({ data }) => {
                setOrderHistory(data);
                setFetching(false);
            })
            .catch(unexpectedErrorFunction)
            return () => wrappedFunction.cancel();
        }
    }
    React.useEffect(() => {
        return refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    function handleClick(order) {
        setActiveOrder(order);
    }
    function showMoreOrders() {
        setOrderPaginationIndex(orderPaginationIndex + PAGINATION_LIMIT)
    }
    const refreshBtn = (
        <button className='small-btn btn white-btn grid-btn full-width-mobile' onClick={refresh}>
            <span><Icon iconName='refresh' width={14} /></span>
            <span>REFRESH</span>
        </button>
    )
    function renderContent() {
        if (fetching) {
            return <LoadingContainer text="Loading orders..." />
        }
        else if (orderHistory && orderHistory.orders && orderHistory.orders.length > 0) {
            return (
                <>
                    <div className='header-container'>
                        <div>
                            <h5 className='underline-heading'>Your order history</h5>
                        </div>
                        <div>{refreshBtn}</div>
                    </div>
                    <ul className='order-history-list'>
                        {orderHistory.orders.slice(0, orderPaginationIndex).map((order) => {
                            const { id, deliveryDate, createdAt, orderTotal, status } = order;
                            return (
                                <li key={id}>
                                    <table>
                                        <tbody>
                                            <tr><td>Order code:</td><td>{id}</td></tr>
                                            <tr><td>Order placed:</td><td>{moment(createdAt).format('LLLL')}</td></tr>
                                            <tr><td>Delivery date:</td><td>{deliveryDate}</td></tr>
                                            <tr><td>Order total:</td><td>${toCurrency(orderTotal)}</td></tr>
                                            <tr><td>Order status:</td><td><span className={`order-status ${status.split(' ')[0].toLowerCase()}`}>{status}</span></td></tr>
                                        </tbody>
                                    </table>
                                    <div className='btn-container'>
                                        <div>
                                            <button className='btn small-btn white-btn' onClick={() => handleClick(order)}>VIEW ORDER DETAILS</button>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    {orderHistory.orders.length > orderPaginationIndex && <div className='add-padding-2 text-center'><button className='btn secondary-outline-btn' onClick={showMoreOrders}>SHOW MORE ORDERS</button></div>}
                </>
            )
        }
        return (
            <div className='text-center add-padding'>
                <div className='account-placeholder'>
                    <div className='image-container'><img src={basketPlaceholder} alt="Empty basket" /></div>
                    <p className='margin-bottom-2'>Your order history will show up here - perhaps you should <Link to={FOOD_ROUTE}>get started</Link> on your first order?</p>
                    <div className='add-padding-2'>{refreshBtn}</div>
                </div>
            </div>
        )
    }
    return (
        <AccountWrapper authUser={authUser}>
            <OrderDialog order={activeOrder} setOrder={setActiveOrder} />
            <div className='account-orders-container'>
                {renderContent()}
            </div>
        </AccountWrapper>
    )
}

export default () => {
    return (
        <Layout pageTitle="Order history">
            <OrdersPage />
        </Layout>
    )
}