import React from 'react';
import { Router } from '@reach/router';
import FavoritesPage from '../components/pageComponents/FavoritesPage';
import OrdersPage from '../components/pageComponents/OrdersPage';
import SettingsPage from '../components/pageComponents/SettingsPage';
import PrivateRoute from '../components/PrivateRoute';

export default () => {
    return (
        <Router basepath="/account">
            <PrivateRoute component={FavoritesPage} path="/favorites/" />
            <PrivateRoute component={OrdersPage} path="/orders/" />
            <PrivateRoute component={SettingsPage} path="/settings/" />
        </Router>
    )
}