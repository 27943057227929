import React from 'react';
import { navigate } from 'gatsby';
import { isEqual } from 'lodash';
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import Icon from './Icon';
import { AuthContext } from './Layout';
import Dialog from './Dialog';
import LoadingContainer from './LoadingContainer';
import ConfirmationDialog from './ConfirmationDialog';
import { formatPhoneNumber, handleLocalStateLogout, postWithToken, setLocalStorageAuthUser, getAxiosErrorMessage } from '../utils';
import ResetPasswordDialog from './ResetPasswordDialog';
import { BASE_PATH } from '../config';

const confirmationDialogProps = {
    header: "Delete account",
    message: "Are you sure you want to permanently delete your account? This cannot be undone!",
    btnClass: "error-btn",
    btnText: "YES, DELETE"
}


export default ({ dialogOpen, setDialogOpen, authUser, setAuthUser }) => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const user = React.useContext(AuthContext);
    function handleClose() {
        setDialogOpen(false);
    }
    function handleDelete() {
        setConfirmationDialogOpen(true);
    }
    const initialValues = {
        name: authUser.displayName,
        phoneNumber: formatPhoneNumber(authUser.phoneNumber),
        email: authUser.email
    }
    return (
        <>
            <button className='btn small-btn primary-outline-btn' onClick={() => setDialogOpen(true)}>EDIT ACCOUNT DETAILS</button>
            <Dialog open={dialogOpen} onClose={handleClose} maxWidth={500} disableBackdropClick>
                <div className='add-padding-2'>
                    <button className='btn close-btn' onClick={handleClose}>
                        <Icon width={18} iconName="times" />
                    </button>
                    <h5 className='margin-bottom-2'>Edit account details</h5>
                    <Formik
                        initialValues={initialValues}
                        validate={({ name, phoneNumber, email }) => {
                            let errors = {};
                            if (!name) {
                                errors.name = "Please enter your name";
                            }
                            else if (name.length > 50) {
                                errors.name = "Name cannot exceed 50 characters"
                            }
                            if (phoneNumber && /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/.test(phoneNumber) === false) {
                                errors.phoneNumber = "Please enter a valid phone number"
                            }
                            if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) === false) {
                                errors.email = "Please enter a valid email";
                            }
                            return errors;
                        }}
                        onSubmit={(values, actions) => {
                            actions.setStatus(null);
                            postWithToken(user.getIdToken(), `${BASE_PATH}/updateUser`, values)
                            .then(({ data }) => {
                                user.reload();
                                actions.setSubmitting(false);
                                setAuthUser(data.user);
                                setLocalStorageAuthUser(data.user);
                                handleClose();
                            })
                            .catch(err => {
                                actions.setStatus(getAxiosErrorMessage(err));
                                actions.setSubmitting(false);
                            })
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, status,  }) => {
                            const disabled = isSubmitting || isEqual(values, initialValues);
                            function renderStatus() {
                                if (status) {
                                    return <div className='status-container text-left error'><span>{status}</span></div>
                                }
                                return undefined;
                            }
                            function renderPhoneNumberMsg() {
                                if (errors.phoneNumber && touched.phoneNumber) {
                                    return <div className='error-msg'>{errors.phoneNumber}</div>
                                }
                                return <div className='helper-text'>Enter a phone number to receive text alerts for deliveries. Standard messaging rates apply.</div>
                            }
                            return (
                                <>
                                    {renderStatus()}
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            name='name'
                                            className={`form-input${touched.name && errors.name ? ' invalid' : ''}`}
                                            type='text'
                                            placeholder='Full name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {touched.name && errors.name && <div className='error-msg'>{errors.name}</div>}
                                        <NumberFormat 
                                            format="(###) ###-####" 
                                            mask="_"
                                            name='phoneNumber'
                                            className={`form-input${touched.phoneNumber && errors.phoneNumber ? ' invalid' : ''}`}
                                            type='tel'
                                            placeholder='Phone number (optional)'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                        />
                                        {renderPhoneNumberMsg()}
                                        <input
                                            name='email'
                                            className={`form-input${touched.email && errors.email ? ' invalid' : ''}`}
                                            type='email'
                                            placeholder='Email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {touched.email && errors.email && <div className='error-msg'>{errors.email}</div>}
                                        <button type='submit' className={`btn full-width primary-btn${disabled ? ' disabled' : ''}`} disabled={disabled}>
                                            {isSubmitting ? 'SAVING...' : 'SAVE CHANGES'}
                                        </button>
                                    </form>
                                </>
                            )
                        }}
                    </Formik>
                    <div className='more-options-container'>
                        <h5 className='margin-bottom-2'>More Options</h5>
                        <div className='flex-btn-container'>
                            <div>
                                <button className='btn white-btn full-width small-btn' onClick={() => setResetPasswordDialogOpen(true)}>RESET PASSWORD</button>
                            </div>
                            <div><button className='btn error-btn full-width small-btn' onClick={handleDelete}>DELETE ACCOUNT</button></div>
                        </div>
                    </div>
                    {deleting && <div className='updating-container'><LoadingContainer text="Deleting your account..." /></div>}
                </div>
            </Dialog>
            <ResetPasswordDialog dialogOpen={resetPasswordDialogOpen} setDialogOpen={setResetPasswordDialogOpen} authUser={authUser} />
            <ConfirmationDialog 
                dialogOpen={confirmationDialogOpen} 
                handleCancel={() => setConfirmationDialogOpen(false)}
                {...confirmationDialogProps}
                handleConfirm={() => {
                    setDeleting(true);
                    setConfirmationDialogOpen(false);
                    user.delete()
                    .then(() => {
                        handleLocalStateLogout();
                        navigate('/', {
                            state: {
                                snackbarMessages: [`Account deleted successfully`]
                            }
                        });
                    })
                    .catch(() => {
                        setDeleting(false);
                        window.alert("An error occurred -- please log out and log in again before deleting your account.");
                    })
                }}
            />
        </>
    )
}