import React from 'react';
import { Link } from 'gatsby';
import Icon from './Icon';

export default ({ authUser, children }) => {
    return (
        <>
            <div className='green-heading layout-container'>
                <div className='profile-container'>
                    <div className='icon-container'>
                        <span>
                            <Icon iconName='user' width={32}/>
                        </span>
                    </div>
                    <div className='name'><h3>{authUser.displayName}</h3></div>
                </div>
                <div className='tabs'>
                    <Link to="/account/favorites/" activeClassName='active'>Favorites</Link>
                    <Link to="/account/orders/" activeClassName='active'>Orders</Link>
                    <Link to="/account/settings/" activeClassName='active'>Settings</Link>
                </div>
            </div>
            <div className='layout-container'>
                <div className='account-container'>
                    {children}
                </div>
            </div>
        </>
    )
}